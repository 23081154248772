html body {
  margin: 0;
  padding: 0;
}
.container-main {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  /* border: 1px solid black; */
}
.sidebar {
  height: 97vh;
  margin-top: 1%;
  width: 25%;
  /* border: 1px solid blue; */
  border-radius: 10px;
  box-shadow: 5px 5px 10px -4px rgba(0, 0, 0, 0.15);
  text-align: center;
  box-sizing: border-box;
  position: fixed;
}
.sidebar-container {
  width: 70%;
  margin: auto;
}
.sidebar-image {
  width: 150px;
  border-radius: 50%;
  box-shadow: 5px 5px 10px -4px rgba(0, 0, 0, 0.15);
}
.sections {
  margin-bottom: 50px;
}
.link-section {
  display: flex;
  justify-content: space-between;
}

.sidebar-icons {
  width: 33%;
}
.portfolio-section {
  /* text-align: left; */
}
.portfolio-section a {
  text-decoration: none;
  color: black;
  font-size: 32px;
  transition: all 0.1s;
}
.portfolio-section a:hover {
  font-weight: 500;
  font-size: 23px;
}

/* Portfolio content */

.portfolio-content {
  /* background-color: aqua; */
  box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 15px;
  width: 65%;
  margin: 36px 5% 36px auto;
  box-sizing: border-box;
  /* overflow-y: scroll; */
  /* height: 90vh; */
}

.portfolio-content-inner {
  width: 70%;
  margin: auto;
}

.portfolio-content-inner .content {
  line-height: 1.6;
  font-size: 22px;
}
.portfolio-content-inner .heading {
  font-size: 32px;
}

.portfolio-content > div {
  margin-bottom: 70px;
}
.skills,
.projects {
  display: flex;
  gap: 17px;
  align-items: center;
  flex-wrap: wrap;
}
.skills .skill img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
.skills .skill,
.projects .project {
  display: flex;
  align-items: center;
  border: 1px solid rgba(92, 91, 91, 0.527);
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 0.2s;
  min-width: 31%;
  box-sizing: border-box;
  justify-content: space-between;
}

.skills .skill:hover,
.projects .project:hover {
  border: 1px solid black;
}

.skills {
  display: flex;
  gap: 17px;
  align-items: center;
  flex-wrap: wrap;
}
.skills .skill img {
  width: 75px;
  height: 75px;
  margin-right: 15px;
}
.skills .skill {
  display: flex;
  align-items: center;
  border: 1px solid rgba(92, 91, 91, 0.527);
  padding: 10px 15px;
  border-radius: 10px;
  transition: all 0.2s;
  flex-basis: 31%;
  box-sizing: border-box;
}

.skills .skill:hover {
  border: 1px solid black;
}

.projects .project img {
  width: 20px;
  height: 20px;
  margin: 0px 7px;
}
.project {
  font-size: 22px;
}

.contact-form input[type="submit"] {
  background-color: rgba(36, 36, 36, 0.89);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background-color: black;
}

.contact-form input,
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
#contact ul li {
  font-size: 24px;
}

@media only screen and (max-width: 1550px) {
  .portfolio-content-inner .content {
    font-size: 18px;
  }
  .portfolio-content-inner .heading {
    font-size: 24px;
  }
  #contact ul li {
    font-size: 18px;
  }
  .portfolio-section a {
    font-size: 22px;
  }
  .skills .skill {
    padding: 5px 10px;
  }
  .skills .skill img {
    width: 50px;
    height: 50px;
  }
  .project {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio-content-inner {
    width: 90%;
  }
  .portfolio-content-inner .content {
    line-height: 1.4;
    font-size: 16px;
  }
  .portfolio-content-inner .heading {
    font-size: 20px;
  }
  #contact ul li {
    font-size: 16px;
  }
  .portfolio-section a {
    font-size: 18px;
  }
  .skills .skill {
    flex-basis: 47%;
  }
  .skills .skill img {
    width: 50px;
    height: 50px;
  }
  .project {
    font-size: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .sections {
    margin-bottom: 10px;
  }
  .portfolio-content-inner {
    width: 90%;
  }
  .portfolio-content-inner .content {
    line-height: 1.4;
    font-size: 16px;
  }
  .portfolio-content-inner .heading {
    font-size: 20px;
  }
  #contact ul li {
    font-size: 16px;
  }
  .portfolio-section a {
    font-size: 18px;
  }
  .skills .skill {
    flex-basis: 95%;
  }
  .skills .skill img {
    width: 50px;
    height: 50px;
  }
  .project {
    font-size: 13px;
  }
  .container-main {
    display: block;
  }
  .sidebar {
    width: 100%;
    position: static;
    background-color: #fff;
    height: auto;
  }
  .portfolio-section {
    padding-bottom: 15px;
  }
  .portfolio-content {
    /* background-color: aqua; */
    box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    margin: 10px 5% 0px auto;
    box-sizing: border-box;
    /* overflow-y: scroll; */
    /* height: 90vh; */
  }
}
